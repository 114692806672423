import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import logo1 from '../assets/img/img-satisfaction@2x.png';
import logo2 from '../assets/img/img-purchase@2x.png';
import logo3 from '../assets/img/img-transformation@2x.png';
import bottomImg from '../assets/img/contact_background.png';

const Benefit = () => {
  return (
    <section className="section benefit">
      <a name="benefit" />
      <Container>
        <Row className="top">
          <Col md={12}>
            <div>
              <div className="section-heading text-center">
                <span>AIVORY는 </span>
                <span className="contrast-text">정보가 있는 곳</span>
                <span>이라면 </span>
                <span className="contrast-text desktop">어디든 적용</span>
                <span className="desktop"> 가능하며</span>
              </div>
              <div className="section-heading text-center mobile">
                <span className="contrast-text">어디든 적용</span>
                <span> 가능하며</span>
              </div>
              <div className="section-heading text-center">
                <span className="contrast-text">다양한 형태로 활용</span>
                <span>할 수 있습니다.</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Row className="company-top" xs={1} lg={3}>
              <span className="logo-box">
                <div className="logo-box-inner">
                  <img src={logo1} alt="..." className="logo1" />
                </div>
                <div className="title">서비스 만족도 향상</div>
                <div className="desc">개인화 맞춤형 콘텐츠 추천을 제공하여</div>
                <div className="desc">
                  서비스 재방문과 체류시간 증가를 이루고
                </div>
                <div className="desc">서비스의 만족도 상승으로 연결됩니다.</div>
              </span>
              <span className="logo-box">
                <div className="logo-box-inner">
                  <img src={logo2} alt="..." className="logo1" />
                </div>
                <div className="title">제품 구매율 증대</div>
                <div className="desc">쇼핑몰 등 e-커머스에서</div>
                <div className="desc">
                  개인에게 필요하다고 판단되는 제품을 추천하여
                </div>
                <div className="desc">
                  제품 구매율의 증대를 기대할 수 있습니다.
                </div>
              </span>
              <span className="logo-box">
                <div className="logo-box-inner">
                  <img src={logo3} alt="..." className="logo1" />
                </div>
                <div className="title">디지털 트랜스포메이션</div>
                <div className="desc">
                  기업 내 업무 도구에 축적된 지식을 학습하고
                </div>
                <div className="desc">유용할 만한 업무 지식을 추천하는 등</div>
                <div className="desc">개인화 맞춤형 추천이 가능합니다.</div>
              </span>
            </Row>
          </Col>
        </Row>
      </Container>
      <img src={bottomImg} alt="..." className="bottombackground" />
    </section>
  );
};

export default Benefit;
