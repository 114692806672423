import React, { useState, useEffect } from 'react';
import './assets/sass/root.scss';
import ReactGA from 'react-ga';
import Navbar from './components/Navbar';
import Heading from './components/Heading';
import Hoxy from './components/Hoxy';
import SearchEngine from './components/SearchEngine';
import Company from './components/Company';
import ContactUs from './components/ContactUs';
// import Footer from './components/Footer';
import ExperienceLink from './components/ExperienceLink';
import Gs from './components/Gs';
import Benefit from './components/Benefit';

function App() {
  const [activeExperiene, setActiveExperiene] = useState(false);
  const handleMouseUp = (event) => {
    if (
      event.target.tagName !== 'BODY' &&
      event.target.id !== 'root' &&
      event.target.className !== 'container'
    ) {
      if (document.getSelection) {
        ReactGA.ga('send', 'event', 'drag', document.getSelection().toString());
      } else if (document.selection && document.selection.type !== 'Control') {
        ReactGA.ga(
          'send',
          'event',
          'drag',
          document.selection.createRange().text
        );
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);
  return (
    <>
      <Navbar setActiveExperiene={setActiveExperiene} />
      <main>
        <Heading />
        <Hoxy />
        <Gs />
        <SearchEngine />
        <Benefit />
        <Company />
        <ContactUs />
        <ExperienceLink
          activeExperiene={activeExperiene}
          setActiveExperiene={setActiveExperiene}
        />
        {/* <Footer /> */}
      </main>
    </>
  );
}

export default App;
