import React, { useState, useEffect, createRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Lottie from 'react-lottie';
import animationData from '../assets/lottie/particle.json';
import logoImg3x from '../assets/img/aibory-logo-main@3x.png';
import logoImg2x from '../assets/img/aibory-logo-main@2x.png';
import logoImg from '../assets/img/aibory-logo-main.png';
import gsImg2x from '../assets/img/ic-gs@2x.png';

const Gs = () => {
  const [responseLogoImg, setResponseLogoImg] = useState(logoImg2x);
  const [gsEl] = useState(() => createRef());
  const [lottieEl] = useState(() => createRef());
  const [isLottieStopped, setIsLottieStopped] = useState(false);

  const handleResize = () => {
    if (window.document.documentElement.offsetWidth > 1200) {
      setResponseLogoImg(logoImg3x);
    } else if (window.document.documentElement.offsetWidth > 800) {
      setResponseLogoImg(logoImg2x);
    } else {
      setResponseLogoImg(logoImg);
    }
  };

  const handleScroll = () => {
    const gs = gsEl.current;
    const gsTop = gs.offsetTop - gs.offsetHeight;

    if (window.scrollY >= gsTop) {
      // gs.classList.add('navbar-sticky');
      // console.log(lottieEl.play);
      setIsLottieStopped(false);
    } else {
      // gs.classList.remove('navbar-sticky');
      setIsLottieStopped(true);
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      className: 'lottie'
    }
  };

  useEffect(() => {
    if (window.document.documentElement.offsetWidth < 1200) {
      setResponseLogoImg(logoImg);
    }
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="gs section text-contrast" ref={gsEl}>
      <a name="aivoryGS" />
      <Container className="container">
        <Col md="12">
          <Row>
            <div className="mx-auto text-center">
              <div className="logo-box">
                <img src={responseLogoImg} alt="..." className="logo" />
              </div>
              <h2 className="title">
                AIVORY와 함께 정보의 가치를 극대화 해보세요!
              </h2>
              <h2 className="title-mobile">AIVORY와 함께</h2>
              <h2 className="title-mobile">정보의 가치를 극대화 해보세요!</h2>

              <div className="gslogo-box">
                <img src={gsImg2x} alt="..." className="gslogo" />
                <span>GS인증 1등급</span>
              </div>
              <Lottie
                ref={lottieEl}
                height={0}
                options={defaultOptions}
                isStopped={isLottieStopped}
                isPaused={false}
              />
            </div>
          </Row>
        </Col>
      </Container>
    </section>
  );
};

export default Gs;
