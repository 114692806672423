import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Scroll from 'react-scroll';
import serchdImg from '../assets/img/ic-searchd-engine.png';
import serchdImg2x from '../assets/img/ic-searchd-engine@2x.png';
import serchdImg3x from '../assets/img/ic-searchd-engine@3x.png';
import recommendImg from '../assets/img/ic-recommend-engine.png';
import recommendImg2x from '../assets/img/ic-recommend-engine@2x.png';
import recommendImg3x from '../assets/img/ic-recommend-engine@3x.png';
import chatbotImg from '../assets/img/ic-chatbot.png';
import chatbotImg2x from '../assets/img/ic-chatbot@2x.png';
import chatbotImg3x from '../assets/img/ic-chatbot@3x.png';
import managerImg from '../assets/img/ic-manager.png';
import managerImg2x from '../assets/img/ic-manager@2x.png';
import managerImg3x from '../assets/img/ic-manager@3x.png';

const { Element } = Scroll;

const SearchEngine = () => {
  const [responseImg, setResponseImg] = useState({
    searchImg: serchdImg2x,
    recommendImg: recommendImg2x,
    chatbotImg: chatbotImg2x,
    managerImg: managerImg2x
  });
  const handleResize = () => {
    if (window.document.documentElement.offsetWidth > 1200) {
      setResponseImg({
        searchImg: serchdImg3x,
        recommendImg: recommendImg3x,
        chatbotImg: chatbotImg3x,
        managerImg: managerImg3x
      });
    } else if (window.document.documentElement.offsetWidth > 800) {
      setResponseImg({
        searchImg: serchdImg2x,
        recommendImg: recommendImg2x,
        chatbotImg: chatbotImg2x,
        managerImg: managerImg2x
      });
    } else {
      setResponseImg({
        searchImg: serchdImg,
        recommendImg,
        chatbotImg,
        managerImg
      });
    }
  };
  useEffect(() => {
    if (window.document.documentElement.offsetWidth < 1200) {
      setResponseImg({
        searchImg: serchdImg,
        recommendImg,
        chatbotImg,
        managerImg
      });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Element name="search-engine">
      <section className="section search-engine">
        <Container>
          <a name="searchEngine" />
          <Row className="package first">
            <Col md="6" sm="6" xs="12">
              <div className="logo-box">
                <img src={responseImg.searchImg} alt="..." className="logo" />
              </div>
            </Col>
            <Col
              md={{ size: 6, order: 'last' }}
              sm={{ size: 6, order: 'last' }}
              xs={{ size: 12, order: 'last' }}
            >
              <div className="feature-desc">
                <div className="section-heading">
                  <h2>검색</h2>
                </div>
                <div className="section-desc">
                  <h5>
                    AIVORY의 검색 엔진은 실시간 색인을 통해
                    <br />
                    보다 빠르고 정확한 정보를 제공합니다.
                  </h5>
                </div>
                <div className="section-detail">
                  <div>
                    <div className="dot" />
                    <p>대용량 데이터에 대해 더 빠른 검색 서비스</p>
                  </div>

                  <div>
                    <div className="dot" />
                    <p>사용성에 기반한 맞춤형 검색 서비스</p>
                  </div>
                  <div>
                    <div className="dot dot-none" />
                    <p>(첨부 파일, 이미지, 다국어 검색)</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <a name="aiRecommend" />
          <Row className="package">
            <Col
              md={{ size: 6, order: 'last' }}
              sm={{ size: 6, order: 'last' }}
              xs={12}
            >
              <div className="logo-box">
                <img
                  src={responseImg.recommendImg}
                  alt="..."
                  className="logo"
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <div>
                <div className="section-heading">
                  <h2>인공지능 추천</h2>
                </div>
                <div className="section-desc">
                  <h5>
                    자체 개발한 인공지능 추천 엔진을 통해
                    <br />
                    사용자들의 이용 패턴을 분석하고
                    <br />
                    맞춤형 콘텐츠를 제공합니다.
                  </h5>
                </div>
                <div className="section-detail">
                  <div>
                    <div className="dot" />
                    <p>빅데이터 없이도 만족도 높은 추천 기능 제공</p>
                  </div>
                  <div>
                    <div className="dot" />
                    <p>10배 이상 절감된 수준의 리소스 요구</p>
                  </div>
                  {/* <div>
                    <div className="dot" />
                    <p>연도별 트렌드 기반 추천</p>
                  </div> */}
                  <div>
                    <div className="dot" />
                    <p>유사이미지 추천</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* <a name="chatbot" />
          <Row className="package">
            <Col md={6} sm={6} xs={12}>
              <div className="logo-box">
                <img src={responseImg.chatbotImg} alt="..." className="logo" />
              </div>
            </Col>
            <Col
              md={{ size: 6, order: 'last' }}
              sm={{ size: 6, order: 'last' }}
              xs={{ size: 12, order: 'last' }}
            >
              <div>
                <div className="section-heading">
                  <h2>챗봇</h2>
                </div>
                <div className="section-desc">
                  <h5>
                    사용자의 질문에 대해
                    <br />
                    빠르고 정확한 답변을 제공하고
                    <br />
                    맞춤화로 더 좋은 정보를 추천합니다.
                  </h5>
                </div>
                <div className="section-detail">
                  <div>
                    <div className="dot" />
                    <p>사용자의 자연어 질문 의도 파악 가능</p>
                  </div>

                  <div>
                    <div className="dot" />
                    <p>질문의도에 대한 맞춤형 추천 콘텐츠 제공</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row> */}
          <a name="analysisTools" />
          <Row className="package">
            {/* <Col
              md={{ size: 6, order: 'last' }}
              sm={{ size: 6, order: 'last' }}
              xs={12}
            >
              <div className="logo-box">
                <img src={responseImg.managerImg} alt="..." className="logo" />
              </div>
            </Col>
            <Col md={6} sm={6} xs={12}> */}
            <Col md="6" sm="6" xs="12">
              <div className="logo-box">
              <img src={responseImg.managerImg} alt="..." className="logo" />
              </div>
            </Col>
            <Col
              md={{ size: 6, order: 'last' }}
              sm={{ size: 6, order: 'last' }}
              xs={{ size: 12, order: 'last' }}
            >
              <div>
                <div className="section-heading">
                  <h2>분석 및 관리 도구</h2>
                </div>
                <div className="section-desc">
                  <h5 className="desc-desktop">
                    트렌드 분석부터 히트맵, 인공지능 리포트까지
                    <br />
                    사용자의 활동을 담은 관리자 페이지를 제공합니다.
                  </h5>
                  <h5 className="desc-mobile">
                    트렌드 분석부터 히트맵,
                    <br />
                    인공지능 리포트까지 사용자의 활동을 담은
                    <br />
                    관리자 페이지를 제공합니다.
                  </h5>
                </div>
                <div className="section-detail">
                  <div>
                    <div className="dot" />
                    <p>실시간 키워드 트렌드 및 검색어 파악 기능</p>
                  </div>
                  <div>
                    <div className="dot" />
                    <p>고객사 홈페이지 히트맵</p>
                  </div>
                  <div>
                    <div className="dot" />
                    <p>AIVORY 활용 관련 브리핑</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Element>
  );
};

export default SearchEngine;
