import React, { useState, useEffect } from 'react';
import { Container, Row, Col, NavbarBrand } from 'reactstrap';
import logoImg3x from '../assets/img/ic-plani-bi@3x.png';
import logoImg2x from '../assets/img/ic-plani-bi@2x.png';
import logoImg from '../assets/img/ic-plani-bi.png';
import iconBlog from '../assets/img/ic-blog@2x.png';
import iconFacebook from '../assets/img/ic-facebook@2x.png';
import iconYoutube from '../assets/img/ic-youtube@2x.png';
import iconTeam from '../assets/img/team@2x.png';

const Heading = () => {
  const [responseLogoImg, setResponseLogoImg] = useState(logoImg2x);

  const handleResize = () => {
    if (window.document.documentElement.offsetWidth > 1200) {
      setResponseLogoImg(logoImg3x);
    } else if (window.document.documentElement.offsetWidth > 800) {
      setResponseLogoImg(logoImg2x);
    } else {
      setResponseLogoImg(logoImg);
    }
  };

  useEffect(() => {
    if (window.document.documentElement.offsetWidth < 1200) {
      setResponseLogoImg(logoImg);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <footer className="footer navbar navbar-expand-md main-nav navigation">
      <Container className="container">
        <Col>
          <Row>
            <Col className="logo-box">
              <NavbarBrand href="https://www.plani.co.kr/" target="_blank">
                <img src={responseLogoImg} alt="..." className="logo" />
              </NavbarBrand>
            </Col>
            <Col className="site-box">
              <NavbarBrand href="http://nerdfactory.ai/" target="_blank">
                <img src={iconTeam} alt="..." className="bi" />
              </NavbarBrand>
              <NavbarBrand href="https://blog.nerdfactory.ai/" target="_blank">
                <img src={iconBlog} alt="..." className="ic" />
              </NavbarBrand>
              {/* <NavbarBrand
                href="https://www.facebook.com/NerdFactory.AI.Plani"
                target="_blank"
              >
                <img src={iconFacebook} alt="..." className="ic" />
              </NavbarBrand> */}
              <NavbarBrand
                href="https://www.youtube.com/watch?v=IBWO-iZcOcc"
                target="_blank"
              >
                <img src={iconYoutube} alt="..." className="ic" />
              </NavbarBrand>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="navbar-nav ">
                <div>042-934-3508</div>
                <div className="split" />
                <div>대전광역시 유성구 문지로 282-10</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="navbar-nav">
                <div>(주)플랜아이</div>
                <div className="split" />
                <div>대표이사 이명기</div>
                <div className="split" />
                <div>사업자번호 305-81-72470</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="navbar-nav">
                <div>© 2020 Plani. All rights reserved.</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="site-box-mobile">
              <NavbarBrand href="http://nerdfactory.ai/" target="_blank">
                <img src={iconTeam} alt="..." className="bi" />
              </NavbarBrand>
              <NavbarBrand href="https://blog.nerdfactory.ai/" target="_blank">
                <img src={iconBlog} alt="..." className="ic" />
              </NavbarBrand>
              {/* <NavbarBrand
                href="https://www.facebook.com/NerdFactory.AI.Plani"
                target="_blank"
              >
                <img src={iconFacebook} alt="..." className="ic" />
              </NavbarBrand> */}
              <NavbarBrand
                href="https://www.youtube.com/watch?v=IBWO-iZcOcc"
                target="_blank"
              >
                <img src={iconYoutube} alt="..." className="ic" />
              </NavbarBrand>
            </Col>
          </Row>
        </Col>
      </Container>
    </footer>
  );
};

export default Heading;
