import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import refimg from '../assets/img/ref.png';
import ans from '../assets/img/logo_ans.svg';
import auri from '../assets/img/logo_auri.svg';
import d from '../assets/img/logo_d.svg';
import bead from '../assets/img/logo_bead.png';
import dicia from '../assets/img/logo_dicia.svg';
import dt from '../assets/img/logo_dt.svg';
import hanwha from '../assets/img/logo_hanwha.svg';
import hit from '../assets/img/logo_hit.svg';
import ibs from '../assets/img/logo_ibs.svg';
import inno from '../assets/img/logo_inno.svg';
import joongbu from '../assets/img/logo_joongbu.svg';
import kaeri from '../assets/img/logo_kaeri.svg';
import kdi from '../assets/img/logo_kdi.svg';
import keit from '../assets/img/logo_keit.svg';
import kiep from '../assets/img/logo_kiep.svg';
import kigam from '../assets/img/logo_kigam.svg';
import kimm from '../assets/img/logo_kimm.svg';
import kistep from '../assets/img/logo_kistep.svg';
import kriso from '../assets/img/logo_kriso.svg';
import nrc from '../assets/img/logo_nrc.svg';
import tech from '../assets/img/logo_tech.svg';
import tipa from '../assets/img/logo_tipa.svg';

const Company = () => {
  return (
    <section className="section company">
      <a name="company" />
      <Container>
        <Row className="top">
          <Col xs={12}>
            <div>
              <div className="title text-center">이미 여러 기업에서</div>
              <div className="title text-center">AIVORY의 가치를 경험하고 계십니다.</div>
            </div>
          </Col>
        </Row>

        <Row sm={5} xs={2}>
          <Col>
            <img src={hanwha} alt={hanwha}/>
          </Col>
          <Col>
            <img src={tipa} alt={tipa}/>
          </Col>
          <Col>
            <img src={inno} alt={inno}/>
          </Col>
          <Col>
            <img src={ans} alt={ans}/>
          </Col>
          <Col>
            <img src={kiep} alt={kiep}/>
          </Col>
          <Col>
            <img src={dt} alt={dt}/>
          </Col>
          <Col>
            <img src={kdi} alt={kdi}/>
          </Col>
          <Col>
            <img src={d} alt={d}/>
          </Col>
          <Col>
            <img src={kaeri} alt={kaeri}/>
          </Col>
          <Col>
            <img src={kimm} alt={kimm}/>
          </Col>
          <Col>
            <img src={ibs} alt={ibs}/>
          </Col>
          <Col>
            <img src={kigam} alt={kigam}/>
          </Col>
          <Col>
            <img src={kistep} alt={kistep}/>
          </Col>
          <Col>
            <img src={tech} alt={tech}/>
          </Col>
          <Col>
            <img src={dicia} alt={dicia}/>
          </Col>
          <Col>
            <img src={hit} alt={hit}/>
          </Col>
          <Col>
            <img src={keit} alt={keit}/>
          </Col>
          <Col>
            <img src={auri} alt={auri}/>
          </Col>
          <Col>
            <img src={nrc} alt={nrc}/>
          </Col>
          <Col>
            <img src={kriso} alt={kriso}/>
          </Col>
          <Col>
            <img src={bead} alt={bead}/>
          </Col>
          <Col>
            <img src={joongbu} alt={joongbu}/>
          </Col>
        </Row>

        {/* 흘러가는 로고 */}
        {/* <Row>
          <Col className="company-col">
            <Row className="company-top">
              <div className="wrapper">
                <div className="sliding-background">
                  <span className="logo-box">
                    <div className="logo-box-inner">
                      <img src={refimg} alt="..." className="logo1" />
                    </div>
                  </span>
                  <span className="logo-box">
                    <div className="logo-box-inner">
                      <img src={refimg} alt="..." className="logo1" />
                    </div>
                  </span>
                </div>
              </div>
            </Row>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
};

export default Company;
