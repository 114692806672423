import React from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledPopover,
  PopoverBody,
  Toast,
  ToastBody,
} from 'reactstrap';
import ReactGA from 'react-ga';
import axios from 'axios';
import classNames from 'classnames/bind';
import phoneIcon from '../assets/img/ic-phone@2x.png';
import mailIcon from '../assets/img/ic-email@2x.png';
import checkIcon from '../assets/img/ic_check@2x.png';
import downArrow from '../assets/img/ic-down@2x.png';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      named: '',
      email: '',
      text: '',
      contactNumber: '',
      isValidate: false,
      isInValidate: false,
      isSubmit: false,
      isFixed: false,
      show: false,
      expTopValueOrigin: 0,
    };
    this.submitEl = React.createRef();
    this.contactUsEl = React.createRef();
  }

  componentDidMount() {
    const experienceLinkEl = document.querySelector('.experienceLink');
    // 문의하기 최상단 좌표
    const elTop =
      this.contactUsEl.current.getBoundingClientRect().top + window.pageYOffset;
    // 체험하기 최상단 좌표
    const experienceLinkElTop =
      elTop + this.contactUsEl.current.offsetHeight + 50;
    this.setState({
      expTopValueOrigin: experienceLinkElTop,
    });
    experienceLinkEl.setAttribute(
      'style',
      `top: ${experienceLinkElTop*1.06}px; height: ${window.innerHeight - 80}px;`
    );
    window.addEventListener('resize', this.resizeHeight);
    window.addEventListener('scroll', this.handleScroll);
  }

  resizeHeight = () => {
    const { expTopValueOrigin } = this.state;
    const experienceLinkEl = document.querySelector('.experienceLink');
    experienceLinkEl.setAttribute(
      'style',
      `top: ${expTopValueOrigin*1.06}px; height: ${window.innerHeight - 80}px;`
    );
  };

  handleScroll = () => {
    const { expTopValueOrigin } = this.state;
    const elTop =
      this.contactUsEl.current.getBoundingClientRect().top + window.pageYOffset;
    const experienceLinkEl = document.querySelector('.experienceLink');
    const experienceLinkElTop =
      elTop + this.contactUsEl.current.offsetHeight + 50;

    if (expTopValueOrigin >= experienceLinkElTop + 50) {
      this.setState({
        isFixed: false,
      });
      experienceLinkEl.setAttribute(
        'style',
        `top: ${expTopValueOrigin*1.06}px; height: ${window.innerHeight - 80}px;`
      );
      this.contactUsEl.current.setAttribute('style', `opacity: 1;`);
    } else if (window.scrollY >= elTop - 80) {
      if (window.innerWidth > 576) {
        this.setState({
          isFixed: true,
        });
        experienceLinkEl.style.boxShadow = '0 -2px 15px 0 rgba(0,0,0,.08)';
        const scrollTop = window.scrollY;
        const height = this.contactUsEl.current.offsetHeight;

        const opacity = 8 - scrollTop / height;
        this.contactUsEl.current.setAttribute('style', `opacity: ${opacity};`);
      }
    }
  };

  webHooksToSlack = (named, email, text, contactNumber) => {
    const options = {
      attachments: [
        {
          fallback: 'AIVORY 소개페이지를 통한 문의하기입니다.',
          color: '#36a64f',
          fields: [
            {
              title: '성함',
              value: named,
              short: false,
            },
            {
              title: '연락처',
              value: contactNumber,
              short: false,
            },
            {
              title: '이메일',
              value: email,
              short: false,
            },
            {
              title: '문의내용',
              value: text,
              short: false,
            },
          ],
        },
      ],
    };

    axios({
      method: 'post',
      url: `https://hooks.slack.com/services/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`,
      timeout: 60000,
      data: JSON.stringify(options),
    })
      .then(() => {
        // console.log('SUCCEEDED: Sent slack webhook: \n', response.data);
        setTimeout(() => {
          this.setState({
            show: false,
          });
        }, 3000);
      })
      .catch(() => {
        // console.log('FAILED: Send', error);
      });
  };

  handleSubmit = () => {
    const { named, email, text, isSubmit, contactNumber } = this.state;
    // event.preventDefault();

    // 문의하기 전송
    if (isSubmit) {
      ReactGA.ga(
        'send',
        'event',
        'click_send_button',
        'success',
        `이름 : ${named}, 번호 : ${contactNumber}, 이메일 : ${email}, 질문 : ${text}`
      );

      this.setState(
        {
          show: true,
        },
        () => {
          this.webHooksToSlack(named, email, text, contactNumber);
          setTimeout(() => {
            this.setState({
              named: '',
              email: '',
              text: '',
              contactNumber: '',
              isSubmit: false,
              isValidate: false,
            });
          }, 2000);
        }
      );
    } else {
      ReactGA.ga(
        'send',
        'event',
        'click_send_button',
        'fail',
        `이름 : ${named}, 번호 : ${contactNumber}, 이메일 : ${email}, 질문 : ${text}`
      );
    }
  };

  handleNameForm = (event) => {
    this.setState(
      {
        named: event.target.value,
      },
      () => {
        this.formCheck();
      }
    );
  };

  handleEmailForm = (event) => {
    this.setState({
      email: event.target.value,
    });
    // if (
    //   /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)
    // ) {
    //   this.setState({
    //     isValidate: true,
    //     isInValidate: false,
    //   });
    // } else {
    //   this.setState({
    //     isValidate: false,
    //     isInValidate: true,
    //   });
    //   if (event.target.value === '') {
    //     this.setState({
    //       isInValidate: false,
    //     });
    //   }
    // }
    // this.formCheck();
  };

  handleTextForm = (event) => {
    this.setState({
      text: event.target.value,
    });
    // this.formCheck();
  };

  handleNumberForm = (event) => {
    this.setState(
      {
        contactNumber: event.target.value,
      },
      () => {
        this.formCheck();
      }
    );
  };

  onFocusName = () => {
    ReactGA.ga('send', 'event', 'click_input_name');
  };

  onFocusEmail = () => {
    ReactGA.ga('send', 'event', 'click_input_email');
  };

  onFocusQuestion = () => {
    ReactGA.ga('send', 'event', 'click_input_question');
  };

  onFocusNumber = () => {
    ReactGA.ga('send', 'event', 'click_input_number');
  };

  formCheck = () => {
    const { named, contactNumber } = this.state;
    if (named !== '' && contactNumber !== '') {
      this.setState({
        isSubmit: true,
      });
      // this.setIsSubmit(true);
    } else {
      this.setState({
        isSubmit: false,
      });
      // this.setIsSubmit(false);
    }
  };

  emailForm = () => {
    const {
      named,
      isValidate,
      isInValidate,
      email,
      text,
      isSubmit,
      show,
      contactNumber,
    } = this.state;
    let form = [];
    form = (
      <Form
        className="gform"
        method="POST"
        action="https://script.google.com/macros/s/AKfycbxGXrBuB5S5BOaQU1hyT_DBWwH2WWRZWXZcf23881ZTnhBjaoFQnTrIHAarJt5f5NKu/exec"
        onSubmit={this.handleSubmit}
      >
        <FormGroup>
          <Label className="form-title">
            <span>성함</span>
            <span className="necessary"> (필수)</span>
          </Label>

          <Input
            type="text"
            name="name"
            value={named}
            className="input-form"
            placeholder="성함을 입력해주세요."
            onChange={this.handleNameForm}
            onFocus={this.onFocusName}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-title">
            <span>연락처</span>
            <span className="necessary"> (필수)</span>
          </Label>

          <Input
            type="text"
            name="number"
            value={contactNumber}
            className="input-form"
            placeholder="연락처를 입력해주세요."
            onChange={this.handleNumberForm}
            onFocus={this.onFocusNumber}
          />
        </FormGroup>
        <FormGroup>
          <Label for="examleEmail" className="form-title">
            이메일
          </Label>

          <Input
            valid={isValidate}
            invalid={isInValidate}
            value={email}
            type="text"
            name="email"
            className="input-form"
            placeholder="이메일을 입력해주세요."
            onChange={this.handleEmailForm}
            onFocus={this.onFocusEmail}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleText" className="form-title">
            문의 내용
          </Label>

          <Input
            type="textarea"
            name="text"
            value={text}
            className="input-form text-form"
            placeholder="제안 또는 문의 내용을 입력해주세요."
            onChange={this.handleTextForm}
            onFocus={this.onFocusQuestion}
          />
        </FormGroup>
        <FormGroup>
          <div className="submit-box">
            <Button
              ref={this.submitEl}
              type="submit"
              id="PopoverLegacy"
              className={classNames('submit', { 'submit-on': isSubmit })}
              // onClick={this.handleSubmit}
            >
              보내기
            </Button>
            <UncontrolledPopover
              trigger="legacy"
              placement="left"
              target="PopoverLegacy"
              disabled={show}
            >
              <PopoverBody>필수 항목을 채워주세요!</PopoverBody>
            </UncontrolledPopover>
          </div>
        </FormGroup>
        <FormGroup>
          <Toast isOpen={show} className="toast">
            <ToastBody>
              <Row>
                <Col md="1">
                  <img src={checkIcon} alt="..." className="checkIcon" />
                </Col>
                <Col md="11">
                  <div>문의가 성공적으로 전달되었습니다!</div>
                  <div className="toast-desc">
                    신속하게 답변 드리도록 하겠습니다. 감사합니다.
                  </div>
                </Col>
              </Row>
            </ToastBody>
          </Toast>
        </FormGroup>
      </Form>
    );
    return form;
  };

  render() {
    const { isFixed } = this.state;
    return (
      <section
        className={classNames('section contactus', {
          'contactus-fixed': isFixed,
        })}
        ref={this.contactUsEl}
      >
        {/* <Element name="contactus"> */}
        <a name="contactus" />
        <Container>
          <Row className="top">
            <Col xs={12}>
              <div>
                <div className="title text-center">지금 연락주세요!</div>
              </div>
            </Col>
          </Row>

          <Row className="contact-form" xs={1} sm={2}>
            <Col sm={7} xs={12} className="mobile">
              {this.emailForm()}
            </Col>
            <Col sm={5} xs={12}>
              <div className="desc-box">
                <div className="desc">AIVORY에 대해 궁금한 것,</div>
                <div className="desc">제안할 것이 있다면</div>
                <div className="desc">지금 바로 문의주세요!</div>
                <div className="nerd-info">
                  <img src={phoneIcon} alt="..." className="icon" />
                  <span className="phone-number">042-820-9620</span>
                </div>
                <div className="nerd-info">
                  <img src={mailIcon} alt="..." className="icon" />
                  <span className="phone-number">contact@nerdfactory.ai</span>
                </div>
              </div>
            </Col>
            <Col sm={7} xs={12} className="desktop">
              {this.emailForm()}
            </Col>
          </Row>
        </Container>
        <Row className="bottom">
          <Col xs={12}>
            <div>
              <div className="arrow text-center">바로 만나보시겠어요?</div>
              <div className="img-box">
                <img src={downArrow} alt="..." className="" />
              </div>
            </div>
          </Col>
        </Row>
        {/* </Element> */}
      </section>
    );
  }
}

export default ContactUs;
