import React from 'react';
import classNames from 'classnames/bind';
import Scroll from 'react-scroll';
import PropTypes from 'prop-types';
import {
  Container,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  Collapse
} from 'reactstrap';
import ReactGA from 'react-ga';
import logoImg from '../assets/img/aibory-logo-3.png';
import logoImg2x from '../assets/img/aibory-logo-3@2x.png';
import logoImg3x from '../assets/img/aibory-logo-3@3x.png';
import toggleImg2x from '../assets/img/ic-menu@2x.png';
import toogleCloseImg2x from '../assets/img/ic-close@2x.png';

const { scroller } = Scroll;

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseLogoImg: logoImg2x,
      activeIntro: true,
      activeSearchEngine: false,
      activeContactUs: false,
      // activeTeam: false,
      activeExperiene: false,
      searchEngineTop: 0,
      contactUsTop: 0,
      experieneTop: 0,
      isSticky: false,
      isCollapsed: true,
      toggleImg: toggleImg2x,
      isLeave: false
    };
    this.navbar = React.createRef();
  }

  componentDidMount() {
    if (window.document.documentElement.offsetWidth < 1200) {
      this.setState({
        responseLogoImg: logoImg
      });
    }

    this.setState({
      searchEngineTop: document.getElementsByClassName('search-engine')[0]
        .offsetTop,
      contactUsTop: document.getElementsByClassName('contactus')[0].offsetTop,
      experieneTop: document.getElementsByClassName('experienceLink')[0]
        .offsetTop
    });
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (window.document.documentElement.offsetWidth > 1200) {
      this.setState({
        responseLogoImg: logoImg3x
      });
    } else if (window.document.documentElement.offsetWidth > 800) {
      this.setState({
        responseLogoImg: logoImg2x
      });
    } else {
      this.setState({
        responseLogoImg: logoImg
      });
    }
  };

  // in case ie
  hasClass = (ele, cls) => {
    return ele.getAttribute('class').indexOf(cls) > -1;
  };

  handleScroll = () => {
    const { searchEngineTop, contactUsTop, experieneTop } = this.state;
    const navigation = this.navbar.current;
    const navTop = navigation.offsetTop + navigation.offsetHeight;
    const scrollpos = window.scrollY || window.pageYOffset;

    if (scrollpos >= navTop) {
      this.setState({
        isSticky: true
      });
    } else {
      this.setState({
        isSticky: false
      });
    }

    if (window.innerWidth < 768) {
      if (scrollpos < searchEngineTop) {
        this.setState({
          activeIntro: true,
          activeContactUs: false,
          activeSearchEngine: false,
          // activeTeam: false,
          activeExperiene: false
        });
      }

      if (scrollpos >= searchEngineTop && scrollpos <= contactUsTop - 400) {
        this.setState({
          activeIntro: false,
          activeContactUs: false,
          activeSearchEngine: true,
          // activeTeam: false,
          activeExperiene: false
        });
      }

      if (scrollpos >= contactUsTop - 600 && experieneTop === 0) {
        this.setState({
          activeIntro: false,
          activeContactUs: true,
          activeSearchEngine: false,
          // activeTeam: false,
          activeExperiene: false
        });
      }

      if (scrollpos >= contactUsTop + 600) {
        this.setState({
          activeIntro: false,
          activeContactUs: false,
          activeSearchEngine: false,
          // activeTeam: false,
          activeExperiene: true
        });
      }
    } else {
      if (scrollpos < searchEngineTop) {
        this.setState({
          activeIntro: true,
          activeContactUs: false,
          activeSearchEngine: false,
          // activeTeam: false,
          activeExperiene: false
        });
      }

      if (
        scrollpos >= searchEngineTop - 300 &&
        scrollpos <= contactUsTop - 400
      ) {
        this.setState({
          activeIntro: false,
          activeContactUs: false,
          activeSearchEngine: true,
          // activeTeam: false,
          activeExperiene: false
        });
      }

      if (scrollpos >= contactUsTop - 600 && experieneTop === 0) {
        this.setState({
          activeIntro: false,
          activeContactUs: true,
          activeSearchEngine: false,
          // activeTeam: false,
          activeExperiene: false
        });
      }

      if (scrollpos >= contactUsTop + 600) {
        this.setState({
          activeIntro: false,
          activeContactUs: false,
          activeSearchEngine: false,
          // activeTeam: false,
          activeExperiene: true
        });
      }
    }
  };

  scrollToIntro = () => {
    if (window.innerWidth < 768) {
      this.toggleNavbarMobile();
    }
    scroller.scrollTo('intro', {
      duration: 1500,
      delay: 100,
      smooth: true
    });

    ReactGA.ga('send', 'event', 'click_intro_button');
  };

  scrollToContactUs = () => {
    const { activeExperiene } = this.state;
    let offset = -window.innerHeight + 700;
    if (window.innerWidth < 768) {
      this.toggleNavbarMobile();
      offset = -90;
    }
    if (activeExperiene && window.innerWidth > 576) {
      offset = -window.innerHeight;
      scroller.scrollTo('contactus', {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset,
        ignoreCancelEvents: true
      });
    } else {
      scroller.scrollTo('contactus', {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset,
        ignoreCancelEvents: true
      });
    }

    ReactGA.ga('send', 'event', 'click_contact_button');
  };

  scrollToSearchEngine = () => {
    let offset = -100;
    if (window.innerWidth < 768 && window.innerWidth > 576) {
      offset = 0;
      this.toggleNavbarMobile();
    } else if (window.innerWidth < 576) {
      offset = -100;
      this.toggleNavbarMobile();
    }
    scroller.scrollTo('search-engine', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset
    });

    ReactGA.ga('send', 'event', 'click_function_button');
  };

  scrollToExperience = () => {
    const { setActiveExperiene } = this.props;
    if (window.innerWidth < 768) {
      this.toggleNavbarMobile();
    }
    scroller.scrollTo('experienceLink', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -80,
      ignoreCancelEvents: true
    });
    setActiveExperiene(true);

    ReactGA.ga('send', 'event', 'click_experience(nav)_button');
  };

  toggleNavbarMobile = () => {
    const { isCollapsed } = this.state;
    let imgsrc;
    let leave;
    if (isCollapsed) {
      imgsrc = toogleCloseImg2x;
      leave = false;
    } else {
      imgsrc = toggleImg2x;
      leave = true;
    }
    this.setState({
      isCollapsed: !isCollapsed,
      toggleImg: imgsrc,
      isLeave: leave
    });
  };

  render() {
    const {
      responseLogoImg,
      activeIntro,
      activeSearchEngine,
      activeContactUs,
      // activeTeam,
      activeExperiene,
      isSticky,
      isCollapsed,
      toggleImg,
      isLeave
    } = this.state;
    return (
      <nav
        ref={this.navbar}
        className={classNames(
          'navbar navbar-expand-md main-nav navigation fixed-top  nav-top',
          { 'navbar-sticky': isSticky }
        )}
      >
        <Container>
          <div className="navbar-top-wrapper">
            <NavbarBrand
              href="/"
              onClick={() => {
                ReactGA.ga('send', 'event', 'click_home_button');
              }}
            >
              <img
                alt="..."
                src={responseLogoImg}
                className="logo logo-sticky"
              />
            </NavbarBrand>
            <Button
              onClick={this.toggleNavbarMobile}
              className="navbar-toggler-button"
            >
              <img alt="..." src={toggleImg} className="toggle-img" />
            </Button>
          </div>

          <Collapse
            navbar
            className={classNames('drop-down-menu', {
              show: !isCollapsed,
              leave: isLeave
            })}
          >
            <Nav navbar>
              <NavItem>
                <NavLink onClick={this.scrollToIntro} active={activeIntro}>
                  AIVORY 소개
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={this.scrollToSearchEngine}
                  active={activeSearchEngine}
                >
                  주요기능
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={this.scrollToContactUs}
                  active={activeContactUs}
                >
                  문의하기
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={this.scrollToExperience}
                  active={activeExperiene}
                >
                  체험하기
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>

          <div className="collapse navbar-collapse">
            <Nav className="nav navbar-nav ml-auto" navbar>
              <NavItem>
                <NavLink onClick={this.scrollToIntro} active={activeIntro}>
                  AIVORY 소개
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={this.scrollToSearchEngine}
                  active={activeSearchEngine}
                >
                  주요기능
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={this.scrollToContactUs}
                  active={activeContactUs}
                >
                  문의하기
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={this.scrollToExperience}
                  active={activeExperiene}
                >
                  체험하기
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Container>
      </nav>
    );
  }
}

Navbar.propTypes = {
  setActiveExperiene: PropTypes.func.isRequired
};

export default Navbar;
