import React, { useRef, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import questionImg from '../assets/img/img-question@2x.png';
import backgroundImg from '../assets/img/background-1@2x.png';
import bubbleImg from '../assets/img/img-question-bubble@2x.png';

const Hoxy = () => {
  const hoxy = useRef(null);
  const handleScroll = () => {
    // const hoxyTop = hoxy.current.getBoundingClientRect().top;
    const hoxyTop = hoxy.current.offsetTop;
    const hoxyel = hoxy.current;

    if (window.scrollY > hoxyTop) {
      hoxyel.classList.add('fixed-top-top');
    } else {
      hoxyel.classList.remove('fixed-top-top');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section ref={hoxy} className="section hoxy">
      <a name="useTrouble" />
      <Container className="container">
        <Col md="12">
          <Row>
            <div className="mx-auto text-center">
              <div className="title">혹시 이런 고민을 하셨나요?</div>
              <div className="desc1">
                유튜O나 넷플릭O처럼 자동으로 추천하고 싶은데..
              </div>
              <div className="desc1-mobile">유튜O나 넷플릭O처럼</div>
              <div className="desc1-mobile">자동으로 추천하고 싶은데..</div>
              <div className="desc2">
                구O처럼 정보를 정확하게 보여주고 싶은데..
              </div>
              <div className="desc3">어떻게 개발하고 인공지능은 뭐지..</div>
              <div className="desc4">누가 해줄 수 없나..</div>
            </div>
          </Row>
          <Row className="bottom-row">
            <div className="mx-auto logo-box">
              <img src={bubbleImg} alt="..." className="logo-bubble" />
              <img src={questionImg} alt="..." className="logo" />
            </div>
          </Row>
        </Col>
      </Container>
      <img src={backgroundImg} alt="..." className="bottombackground" />
    </section>
  );
};

export default Hoxy;
