import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Scroll from 'react-scroll';
import networkImg from '../assets/img/img-network@2x.png';

const { Element } = Scroll;

const Heading = () => {
  return (
    <Element name="intro">
      <a name="intro" />
      <header className="header section text-contrast">
        <Container className="container">
          <Col md="12">
            <Row>
              <div className="mx-auto text-center">
                <div className="box">
                  <h1 className="title">인공지능 시대에 걸맞는</h1>
                  <h1 className="title-bold">
                    정보와 사용자를 연결하는 최고의 솔루션
                  </h1>
                  <h1 className="mobile-title-bold">
                    정보와 사용자를 연결하는
                  </h1>
                  <h1 className="mobile-title-bold ">최고의 솔루션</h1>
                  <img src={networkImg} alt="..." className="networkImg" />
                </div>
              </div>
            </Row>
          </Col>
        </Container>
      </header>
    </Element>
  );
};

export default Heading;
