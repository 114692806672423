import React, { createRef } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';
// import imgbackground from '../assets/img/img_background.png';
import Footer from './Footer';
import Company from './Company';

const { scroller } = Scroll;

class ExperienceLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEnter: false,
      isOut: true,
      isFullEnter: false,
    };
    this.experienceLinkEl = createRef();
  }

  componentDidMount() {
    const screenBottom = window.scrollY + window.innerHeight;
    const elTop =
      this.experienceLinkEl.current.getBoundingClientRect().top +
      window.pageYOffset;

    if (screenBottom > elTop) {
      this.setState({
        isEnter: true,
        isOut: false,
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  resizeHeight = () => {
    const experienceLinkEl = document.querySelector('.experienceLink');
    experienceLinkEl.setAttribute(
      'style',
      `height: ${window.innerHeight - 80}px;`
    );
  };

  handleScroll = () => {
    const { activeExperiene, setActiveExperiene } = this.props;
    const { isEnter, isOut, isFullEnter } = this.state;
    const screenBottom = window.scrollY + window.innerHeight;
    const elTop =
      this.experienceLinkEl.current.getBoundingClientRect().top +
      window.pageYOffset;
    if (screenBottom > elTop && !isEnter && isOut && !activeExperiene) {
      this.setState({
        isEnter: true,
        isOut: false,
      });
    } else if (window.scrollY >= elTop - 80 && activeExperiene) {
      this.setState(
        {
          isFullEnter: true,
        },
        () => {
          setActiveExperiene(false);
        }
      );
    } else if (window.scrollY >= elTop && isEnter) {
      this.setState(
        {
          isFullEnter: true,
        },
        () => {
          setActiveExperiene(false);
        }
      );
    } else if (screenBottom < elTop && isFullEnter) {
      this.setState({
        isEnter: false,
        isFullEnter: false,
        isOut: true,
      });
    }
  };

  render() {
    return (
      <section ref={this.experienceLinkEl} className="section experienceLink">
        <a name="experienceLink" />
        <div className="bottom-background">
          <Container>
            <Row className="bottom">
              <Col xs={12}>
                <div className="box-position">
                  <div className="text-box">
                    <div className="text-center">
                      <span>전문가가 제안하는 </span>
                    </div>
                    <div className="text-center">
                      <span className="contrast-text">인공지능 솔루션 </span>
                      <span>지금 만나 보세요</span>
                    </div>
                  </div>
                  <div className="text-mini-box text-center">
                    <span>
                      본 페이지는 AIVORY의 추천을 경험할 수 있는 체험
                      페이지입니다.
                    </span>
                  </div>
                  <div className="text-center button-center">
                    <Button
                      className="button-exp"
                      href="http://demo1.nerdfactory.ai/"
                      target="_blank"
                      onClick={() => {
                        ReactGA.ga(
                          'send',
                          'event',
                          'click_goExperience_button'
                        );
                      }}
                    >
                      <div>체험하기</div>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <Company /> */}
        <Footer />
      </section>
    );
  }
}

ExperienceLink.propTypes = {
  activeExperiene: PropTypes.bool.isRequired,
  setActiveExperiene: PropTypes.func.isRequired,
};

export default ExperienceLink;
