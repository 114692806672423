import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/es/object/entries';
import 'core-js/features/object/entries';
import Promise from 'promise-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (!window.Promise) {
  window.Promise = Promise;
}

if (!Object.entries) {
  Object.entries = function (obj) {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array
    while (i) {
      resArray[i] = [ownProps[i], obj[ownProps[i]]];
      i -= 1;
    }

    return resArray;
  };
}

(function () {
  // get all data in form and return object
  function getFormData(form) {
    const { elements } = form;
    let honeypot;

    const fields = Object.keys(elements)
      .filter(function (k) {
        if (elements[k].name === 'honeypot') {
          honeypot = elements[k].value;
          return false;
        }
        return true;
      })
      .map(function (k) {
        if (elements[k].name !== undefined) {
          return elements[k].name;
          // special case for Edge's html collection
        }
        if (elements[k].length > 0) {
          return elements[k].item(0).name;
        }
      })
      .filter(function (item, pos, self) {
        return self.indexOf(item) === pos && item;
      });

    const formData = {};
    fields.forEach(function (name) {
      const element = elements[name];

      // singular form elements just have one value
      formData[name] = element.value;

      // when our element has multiple items, get their values
      if (element.length) {
        const data = [];
        for (let i = 0; i < element.length; i += 1) {
          const item = element.item(i);
          if (item.checked || item.selected) {
            data.push(item.value);
          }
        }
        formData[name] = data.join(', ');
      }
    });

    // add form-specific values into the data
    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || 'responses'; // default sheet name
    formData.formGoogleSendEmail = form.dataset.email || ''; // no email by default

    return { data: formData, honeypot };
  }
  function disableAllButtons(form) {
    const buttons = form.querySelectorAll('button');
    for (let i = 0; i < buttons.length; i += 1) {
      buttons[i].disabled = true;
    }
  }

  function handleFormSubmit(event) {
    // handles form submit without any jquery
    event.preventDefault(); // we are submitting via xhr below
    const form = event.target;
    const formData = getFormData(form);
    const { data } = formData;

    // If a honeypot field is filled, assume it was done so by a spam bot.
    if (formData.honeypot) {
      return false;
    }

    disableAllButtons(form);
    const url = form.action;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    // xhr.withCredentials = true;
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        form.reset();
        const formElements = form.querySelector('.form-elements');
        if (formElements) {
          formElements.style.display = 'none'; // hide form
        }
        const thankYouMessage = form.querySelector('.thankyou_message');
        if (thankYouMessage) {
          thankYouMessage.style.display = 'block';
        }
      }
    };
    // url encode form data for sending as post data
    const encoded = Object.keys(data)
      .map(function (k) {
        return `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`;
      })
      .join('&');
    xhr.send(encoded);
  }

  function loaded() {
    // bind to the submit event of our form
    const forms = document.querySelectorAll('form.gform');
    for (let i = 0; i < forms.length; i += 1) {
      forms[i].addEventListener('submit', handleFormSubmit, false);
    }
  }
  document.addEventListener('DOMContentLoaded', loaded, false);
})();

const env = process.env.NODE_ENV;

function noop() {}
if (env !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
  const tagManagerArgs = {
    gtmId: 'GTM-5W4BWZF',
  };

  // const history = createBrowserHistory();
  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize('UA-131502516-10');
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
}

// const agent = navigator.userAgent.toLowerCase();

// const isChrome = agent.indexOf('chrome');
// const isEdge = agent.indexOf('edge');

// if (isChrome > -1) {
//   if (isEdge > -1) {
//     alert('이 사이트는 크롬 브라우저에 최적화되어 있습니다.');
//   }
// } else {
//   alert('이 사이트는 크롬 브라우저에 최적화되어 있습니다.');
// }
ReactDOM.render(<App />, document.getElementById('root'));

// render(document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
